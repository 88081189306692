import React, {Component} from 'react';
import {connect} from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import {Row,Col,Table,Button,Modal,Input,Label} from "reactstrap";
import Select from 'react-select';
import history from "../../history";
import slugify from 'slugify';
import * as apiService from "../../services/api.service";
import {convertDate} from "../../services/utils.service";
import { bindActionCreators } from "redux";
import * as configurationActions from "../../actions/configurationActions";
import { confirmAlert } from 'react-confirm-alert';  
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getReactAppExtranet,getReactAppFamily} from '../../services/utils.service';



class ConfiguratorSelectionComponent extends Component {

  state = {
    lists:[],list: null, create_update_list:false,
    new_list:false,    errorListInput: null,
    list_title:"",list_type:"",list_id:"",
    clientInput:false,client:"",monument:"",
    sortOrderUp:{updatedAt:true,reference:true,uniqId:true,graniteMain:true,layout:true},
    monuments: []
  };

  componentDidMount(){
    apiService.get(`/api/selection/lists`).then(lists => {
      lists = lists.filter(list=>list.type && list.type !=="");
      this.setState({lists: lists});
    // We put the last list used to avoid selecting it each time
      if (lists && lists.length>0 && this.props.lastSelection){
        if (lists.some(list=> list.value === this.props.lastSelection.value)){
          this.onSelectKind(this.props.lastSelection);
        }
      }
    });
  }
  uniqid = ()=> {
    // Not really unique but not too long very few people will be using this feature
    return (new Date().getTime() + Math.floor((Math.random()*10000)+1)).toString(16);
    };
  getImageUrl = (reference,graniteMain,graniteSecondary)=>{
    return (process.env.REACT_APP_CONFIG3D_ORIGIN || "http://localhost:3010") + `/view/monument/iso/${reference}/${graniteMain}-${graniteMain}--${graniteSecondary}/small/${reference}.jpg`;
  }

  cancelInput = () =>{ this.setState({create_update_list:false,errorListInput:null})};

  newList = ()=>{ this.setState({create_update_list:true,new_list:true,list_title:"",list_id:"",list_type:""})}
  
  renameList = ()=>{ 
    this.setState({create_update_list:true,new_list:false,list_title:this.state.list.label,
                   list_id:this.state.list.value,list_type:this.state.list_type})}
 
  deleteListConfirm = () =>{
    if (this.state.monuments.length >0){
      confirmAlert({ 
        customUI: ({ onClose }) => {
          return (
            <div className='SelDeleteConf'>
              <p>Tous les monuments de cette sélection seront effacés.<br/> Confirmez-vous ?</p>
              <Row>
                <Col md="6"> <Button onClick={() => {this.deleteList();onClose();}}>Oui</Button></Col>
                <Col md="6"> <Button onClick={onClose}>Non</Button></Col>
              </Row>
            </div>
          );
        }
      });
    }else{
      this.deleteList()
    } 
  }
  deleteList = () => {
    apiService.del(`/api/selection/list/${this.state.list.value}`).then(lists => {
    this.setState({list:null,lists:lists,list_title:"",list_id:"",list_type:"",monuments:[]});
    })
   }

  onSelectKind = (selection) => { /* Get new list on network */ 
  apiService.get(`/api/selection/list/${selection.value}`).then(list => {
    if (list && list.length>0 && list[0].config){
      this.setState({list: selection,monuments:list,list_title:selection.label,list_id:selection.value,list_type:selection.type });  
    }else{ // empty list
      this.setState({list:selection, monuments:[],list_title:selection.label,list_id:selection.value,list_type:selection.type });  
    }
    this.props.configActions.changeSelection(selection);
    }); 

  }
  titleChange = (event)=>{const title = event.target.value;
    this.setState({list_title:title,list_id:slugify(title)})};

  typeChange = (event)=>{const type = event.target.value;
    this.setState({list_type:type})};    
  
  identifierChange = (event)=>{
  this.setState({list_id:event.target.value})}
  
  createOrUpdateList=()=> {
    if (this.state.new_list && this.state.lists.find(elt=>elt.value === this.state.list_id )){
      this.setState({errorListInput:{duplicateList:true}});
    }else if (this.state.new_list && !this.state.list_id){
      this.setState({errorListInput:{listNameEmpty:true}});
    }else if (this.state.new_list && !this.state.list_type){
      this.setState({errorListInput:{listTypeEmpty:true}});
    }
    else{
     apiService.post(`/api/selection/list`,{previous:!this.state.new_list && this.state.list.value ,label:this.state.list_title,
                     value:this.state.list_id,type:this.state.list_type}).then(lists => {  
      let newMonuments = this.state.new_list ? []:this.state.monuments;
      this.setState({errorListInput:false,lists: lists,monuments:newMonuments,list:{label:this.state.list_title,value:this.state.list_id,type:this.state.list_type},create_update_list:false})});
     }
    } 
 
  getNewConfig=()=>{
    const {monument,patterns,accessories} = this.props.configuration;
    const p = patterns.map((elt)=>(elt.reference));
    const a = accessories.map((elt)=>(elt.reference)); 
    return ({ uniqId:this.uniqid(),reference:monument.reference, graniteMain:monument.graniteMain, patterns:p,accessories:a})
  }
  addConfigToList =() =>{
     apiService.post(`/api/selection/monument`,{uniqId:this.uniqid(),listId:this.state.list_id,listName:this.state.list_title,
      listType:this.state.list_type,config:this.props.configuration}).then(monuments => {
      this.setState({monuments:monuments});
     });
  }
  deleteMonument = (itemToDelete)=>{
     apiService.del(`/api/selection/monument/${itemToDelete.uniqId}`).then(monuments => {
     if (monuments && monuments.errno){
     }else{
      this.setState({monuments:monuments})
     }
    })
  }
  generateImgMonument = (monument,rotate=false)=>{
    if(monument){
      apiService.get(`/api/selection/generateimage/?listId=${monument.listId}&uniqId=${monument.uniqId}&rotate=${rotate}`).then(result => {
        });
    }
    else{
      apiService.get(`/api/selection/generateimage/?listId=${this.state.list_id}`);
      // La génération peut-être longue on n'attend pas le retour
  }
}
  
  removeElementFromMonument = (itemToDelete)=>{
    let newList = [...this.state.monuments];
    return( newList.filter(item=> item.uniqId !== itemToDelete.uniqId ));
  }
 replaceMonument = (itemToReplace)=>{
   // On efface le précédent et on crée un nouveau car il peut y avoir un changement de monument ou de prix
   apiService.del(`/api/selection/monument/${itemToReplace.uniqId}`).then(answer=>
   apiService.post(`/api/selection/monument`,{uniqId:this.uniqid(),listId:this.state.list_id,listName:this.state.list_title,
    config:this.props.configuration}).then(monuments => {
    this.setState({monuments:monuments});
  }));
  }
  getUrl =  (monument) => getReactAppExtranet(`/config3d/selection/${monument.uniqId}`);
  getUrlFamily =  () => getReactAppFamily() +`/famille/${this.state.client}/selection/${this.state.monument.uniqId}`;
  goToConfig = (monument)=>{
    history.push({pathname: `/selection/${monument.uniqId}`});
  }
  sort =(field)=>{
    const up =this.state.sortOrderUp[field];
    let t1="",t2="";
    const newMonuments = this.state.monuments.sort(function(a, b){
      if (field === "uniqId" || field ===  "updatedAt"){ t1 =a[field];t2= b[field] }
      else{ t1 =a.config.monument[field]; t2 =b.config.monument[field]}
      if (up){
        if(t1<t2){return -1}
        if(t1>t2){return 1}
      }else{
        if(t1<t2){return 1}
        if(t1>t2){return -1}
      }
      return 0
    });
    let newSort = {...this.state.sortOrderUp};
    newSort[field] = !this.state.sortOrderUp[field];
    this.setState({monuments:newMonuments,sortOrderUp:newSort});
  }

  render() {
    const {errorListInput,list_type} = this.state;
    return (
      <div className="SelectionStyle" >   
      <Modal isOpen={this.state.clientInput} toggle={ ()=>this.setState({clientInput:false,monument:""})} className="SelectionClientCopy">
         <Label >CA du client</Label>
         <Input name="client"  onChange={((e)=> this.setState({client:e.target.value}))} />
         <Button color="primary" onClick={()=>this.setState({clientInput:false,monument:""})}>
           <CopyToClipboard text={this.getUrlFamily()} ><span>Copier l'URL Famille dans le presse-papier</span></CopyToClipboard>
         </Button>
      </Modal>
      <Row>
        <Col md="3" className="HeaderSel">
          <Select value={this.state.list} onChange={this.onSelectKind} options={this.state.lists} placeholder="Choisir une liste"/>
        </Col>
        {!this.state.create_update_list && 
          <Col md="2" className="ButtonSel">
            <Button  className="FullFontWidth" onClick={this.newList}> Nouvelle Liste</Button> 
          </Col>   }  
          {this.state.list && !this.state.create_update_list &&
           <Col md="4">
            <Row>
              <Col md="5" className="ButtonSel">
                  <Button  className="FullFontWidth" onClick={this.renameList}>Renommer Liste</Button> 
              </Col>  
              <Col md="5" className="ButtonSel"> 
                <Button  className="FullFontWidth" onClick={this.deleteListConfirm}>Effacer liste</Button>
              </Col>  
            </Row>
          </Col>
                  }            
        {this.state.create_update_list && 
        <Col md="9">
          <Row>
              <Col md="3">
                Nom liste 
                <input className="FullFontWidth" type="text"
                  value={this.state.list_title} onChange={this.titleChange}/>
                  {errorListInput && errorListInput.duplicateList && <p className ="SelError">Ce nom de liste existe déjà</p>}
                  {errorListInput && errorListInput.listNameEmpty && <p className ="SelError">Le nom de la liste est vide</p>}                                      
              </Col>
              <Col md="3">
                Type (shop,...)
                <input className="FullFontWidth" type="text"
                  value={list_type} onChange={this.typeChange}/>
                  {errorListInput && errorListInput.listTypeEmpty && <p className ="SelError">Le type de la liste est vide</p>}                  
              </Col>              
              <Col md="2">
                Identifiant Liste
                <input  className="FullFontWidthRo" type="text" readOnly
                  value={this.state.list_id} onChange={this.identifierChange}/>
              </Col>
              <Col md="2" className="ButtonSel"> 
                <Button  className="FullFontWidth" onClick={this.createOrUpdateList}> Valider</Button>
              </Col>
              <Col md="2" className="ButtonSel"> 
                <Button  className="FullFontWidth" onClick={this.cancelInput}> Annuler</Button>
              </Col>
            </Row>
          </Col>
        }
        </Row>        
        <br/>
        {this.state.list && <div className="SelMonumentsList">        
            <Button className="SelAddConf" onClick={this.addConfigToList}> Ajouter la configuration actuelle à la liste            
             <img className="SelImgMini" src={this.props.isoView.current}  alt="monument" />
            </Button>
            <br/> <br/>
            <Table hover size="sm">
              <thead>
                <tr>
                  <th colSpan="10">Liste des monuments</th>
                  {list_type.toUpperCase() === "CATA" &&<th colSpan="2" onClick={()=>this.generateImgMonument()}>Générer toutes les images <i className="icon material-icons" style={{cursor:"pointer"}}>image</i></th>}
                </tr>
                <tr>
                  <th className="sort" onClick={()=>this.sort("uniqId")}>Id</th>
                  <th>Image</th>
                  <th className="sort" onClick={()=>this.sort("reference")}>Référence</th>
                  <th className="sort" onClick={()=>this.sort("graniteMain")}>Granit</th>
                  <th className="sort" onClick={()=>this.sort("layout")}>Recette</th>
                  <th>Semelle</th>
                  <th>Motifs</th>    
                  <th>Accessoires</th>  
                  <th className="sort" onClick={()=>this.sort("updatedAt")}>Date de modification</th>
                  <th>Voir</th>                        
                  <th>Suppr.</th>    
                  <th>Copier URL</th>  
                  <th>Copier URL Famille</th> 
                  {list_type.toUpperCase() === "CATA" && <th>Générer image</th>  }
                  {list_type.toUpperCase() === "CATA" && <th>Générer image rotation</th>  }
                  {/* <th>Remplacer par config actuelle</th>             */}
                </tr>              
              </thead>
              <tbody>
              {this.state.monuments && this.state.monuments.map((monument,index)=>{
                const {config} = monument;
                return(
                <tr key={index}>
                  <td>{monument.uniqId}</td>
                  <td><img className="SelImgMini" src={this.getImageUrl(config.monument.reference,config.monument.graniteMain,config.monument.graniteSecondary)} alt="monument"/></td>
                  <td>{config.monument.reference}</td>
                  <td>{config.monument.graniteMain}</td>
                  <td>{config.monument.layout}</td>
                  <td>{config.frame.kind}</td>
                  <td>{config.patterns.map(i=>i.reference).join(",")}</td>    
                  <td>{config.accessories.map(i=>i.reference).join(",")}</td>  
                  <td >{convertDate(monument.updatedAt)}</td>  
                  <td style={{cursor:"pointer"}} onClick={()=>this.goToConfig(monument)}><i className="icon material-icons">visibility</i></td>                                                                
                  <td style={{cursor:"pointer"}} onClick={()=>this.deleteMonument(monument)}><i className="icon material-icons">delete</i></td> 
                  <td style={{cursor:"pointer"}} ><CopyToClipboard text={this.getUrl(monument)} ><i className="icon material-icons">link</i></CopyToClipboard></td>     
                  <td style={{cursor:"pointer"}} onClick={()=>this.setState({clientInput:true,monument:monument})} ><i className="icon material-icons">link</i></td> 
                  {list_type.toUpperCase() === "CATA" && <td style={{cursor:"pointer"}} onClick={()=>this.generateImgMonument(monument)}><i className="icon material-icons">image</i></td>  }
                  {list_type.toUpperCase() === "CATA" && <td style={{cursor:"pointer"}} onClick={()=>this.generateImgMonument(monument,true)}><i className="icon material-icons">cached</i></td>  }
                </tr>)
              })}
                </tbody>
            </Table>
        </div>}
      </div>
    );
  }
}

export const ConfiguratorSelection = connect(
  (state) => ({
    configuration: state.configurator.current.configuration,
    lastSelection: state.configurator.lastSelection,
    isoView: state.isoView,
  }),
  (dispatch) => ({
      configActions: bindActionCreators(configurationActions, dispatch)
  })
)(ConfiguratorSelectionComponent);


import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cn from 'classnames';

import {LazyImage} from '../../../components/LazyImage';
import {FavoriteStar} from './FavoriteStar';
import {getMonumentImage} from '../../../services/image.fct.service';
import {getFootstoneCode} from '../../../services/format.service';
import {getDescription} from "../../../services/utils.service";
import { gtmFamily } from '../../../services/gtmFamily';
const loadingImage = require("../../../images/loader@4x.gif");
const ShoppingCartRoundedRedImage = require("../../../images/ShoppingCartRoundedRed.svg");
const ShoppingCartRoundedBlueImage = require("../../../images/ShoppingCartRoundedBlue.svg");

const MonumentCardComponent = ({monument, onSelect, user, hoverInfo,family,finishLoad, showPrices = false, prices = [], color}) => {
  
  let {reference,category,headstone,pattern} = monument;

  const description = getDescription(monument);

  let footstoneName = `GPG ${getFootstoneCode(monument.footstone)}`;
  let monumentCata = false
  if (monument.catalog && monument.catalog.name !== "") {
    footstoneName = monument.catalog.name
    monumentCata = true
  }
  const headstoneName = headstone ? `Stèle ${headstone}` : '';
  const patternName = pattern ? ((headstoneName ? ' — ' : '') + `Motif ${pattern}`) : '';
  const hoverData = hoverInfo && hoverInfo(monument);
  const priceHt = prices && prices[monument.reference] ? (parseInt(prices[monument.reference].discounted)>0? new Intl.NumberFormat('fr-FR').format(Math.ceil(prices[monument.reference].discounted)):'---'):0;
  const priceTtc = prices && prices[monument.reference] ? (parseInt(prices[monument.reference].selling)>0 ? new Intl.NumberFormat('fr-FR').format(Math.ceil(prices[monument.reference].selling)):'---'):0;

  const price = monument.price ? (parseInt(monument.price)>0 ? new Intl.NumberFormat('fr-FR').format(Math.ceil(monument.price)):'---'):0;

  return (
    <div className={cn("MonumentCard", "Category-" + category, {Unavailable: !monument.available})} style={{"--f-main-color": color}}>
      <div className="Monument" onClick={monument.available && !hoverInfo && priceHt != '---' && priceTtc != '---'? onSelect: undefined} style={priceHt == '---' && priceTtc == '---'?{cursor: "not-allowed", opacity: "0.7"}:{}}>
        <LazyImage className="Monument" src={getMonumentImage(monument,"thumbnail")} hoverInfo={hoverData} fromFamily={family} />
        {!monument.available && (
          <div className="UnavailableMessage">
            <p>Pour utiliser ce monument, contactez votre commercial
              {user.identity && user.identity.salesperson && (<span>
                <span> {user.identity.salesperson.firstname + ' ' + user.identity.salesperson.lastname}</span>
                {user.identity.salesperson.phone && (<span> ({user.identity.salesperson.phone})</span>)}
              </span>)}
            </p>
          </div>
        )}

        <div className="Infos">
          {
            monument.alternativeMonumentName ?
              <span className="Footstone" title={monument.reference}>{monument.alternativeMonumentName}</span>
            :
            <>
              <span className="Footstone" title={monument.reference}>{footstoneName}</span>
              {!monumentCata && headstoneName && (<span className="Headstone">{headstoneName}</span>)}
            </>
          }
         

          {patternName && (<span className="Pattern">{patternName}</span>)}
          {description && (<span className="Description">{description}</span>)}
          {showPrices && <div className="Price">
            <span className="Priceht"><img src={ShoppingCartRoundedRedImage} style={{width: "18px", height: "16px"}} alt="Chargement"/> {priceHt? <span>{priceHt}€ <small>HT</small></span>:<span><img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}</span>
            <span className="Pricettc"><img src={ShoppingCartRoundedBlueImage} style={{width: "18px", height: "16px"}} alt="Chargement"/> {priceTtc? <span>{priceTtc}€ <small>TTC</small></span>:<span><img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}</span>
          </div>}
          {family && <div className="Price"> 
            {price? <span>A partir de <b>{price}€<sup>*</sup></b></span>: <span>A partir de <img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}
          </div>}
        </div>
      </div>
      {monument.available && (<FavoriteStar reference={reference}/>)}
      {monument.isFeatured && <div className="Featured" title="Sélection GPG Granit"><img src={require("./monument-incontournable-icon.png")} alt="Incontournable" /></div>}
    </div>
  );
};

MonumentCardComponent.propTypes = {
  onSelect: PropTypes.func.isRequired,
  monument: PropTypes.object.isRequired,
};

export const MonumentCard = connect(
  state => ({
    user: state.user
  })
)(MonumentCardComponent);

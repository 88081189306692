import React, {useState, useRef, useEffect, useContext} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions' ;
import * as _ from 'lodash';
import {Link, NavLink} from 'react-router-dom';
import cn from "classnames";
import {ModalConfigurationDescription} from './completion/Details';
import * as configurationActions from "../../actions/configurationActions";
import {imgs} from '../../services/images.service';
import * as uiActions from '../../actions/uiActions';
import {userCan} from '../../services/userRights.service';
import {PlaceOrderModalButton} from './completion/PlaceOrderModal';
import {goToAndFocusItem} from '../../services/goToAndFocusItem.service';
import {FrameOptionPanelId} from './components/FrameOptionPanel';
import {VeneerOptionPanelId} from './components/VeneerOptionPanel';
import {formatFaceToId} from '../../services/format.service';
import {getPatternSize} from '../../services/patterns.service';
import {isMonumentTombale, configurationOrdered, totalPricePackageToDisplay, getReactAppExtranet, getMonumentCategory} from '../../services/utils.service';
import {
  isFrameDimensionsTooBig,
  isVeneerDimensionsTooBig,
  messageDimensionsTooBig,
  isConfigRequested,
} from '../../services/configurationWarningChecks';

import {
  formatFrame,
  formatMonumentName,
  formatMonumentReference,
  formatEngravingName,
  getGraniteName,
  getAndFormatFace,
  getFace,
  formatQuantity,
} from '../../services/format.service'

import {CompletionLine} from './completion/CompletionLine'
import {ProjectMetaInfoEditor, MetaInfoInput} from './completion/ProjectMetaInfoEditor'
import {AdditionalLineForm} from './completion/AdditionalLineForm'
import {Totals} from './completion/CompletionTotals'
import {ProjectPaymentSettings} from './completion/ProjectPaymentSettings'

import {CreateVariantButton} from '../../components/CreateVariantButton'
import * as apiService from "../../services/api.service";
import {View3D} from './components/View3D'
import {Share} from './components/Share'
import {Flowers} from './components/Flowers';
import {Documents} from './components/Documents'
import imagePack from '../../images/pose.svg';
import imagePresgrav from '../../images/icone-prestgrav.png';
import {Sclient} from '../family_space/create_space/Sclient';
import {displayGranitConfigImage,displayAcccessoryConfigImage} from '../../services/image.fct.service';
import {isFromStock, isQuote} from '../../services/restrictedConfiguration';
import MediaQuery from "react-responsive/src";
import {getStore} from '../../store';
import { TranslationContext } from "./../../context/TranslationContext";
import { Modal } from './../../components/Modal';
import {ConfigurationIsoView} from './components/ConfigurationIsoView';
import {getFootstoneCode} from './../../services/format.service';
import {price} from '../../services/format.service';
import {Steps} from "intro.js-react";
import {introIsAvailable} from "../helpers/introHelpers"
import {FooterWithRouter, DefaultFooterTitle } from './components/Footer';

import {config} from '../../config';
const moment = require('moment');
const DEFAULT_BEDDING = 5;

function ConfiguratorCompletionComponent(props) {
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [flagCloseTutorial, setFlagCloseTutorial] = useState(false);
  const [additionalGraniteNames, setAdditionalGraniteNames] = useState(null); 
  const stepsRef = useRef(null);

  const onBeforeExit = (stepIndex) => {
    const nbSteps = steps.length - 1;
    if (nbSteps !== -1 && flagCloseTutorial === false) {
      if (stepIndex !== nbSteps) {
        const stepsRefValue = stepsRef.current.introJs;
        stepsRefValue.nextStep();
        return false;
      }
    }
  };

  const skipTutorialFinalization = () => {
    setFlagCloseTutorial(true);
  };

  const onExit = () => {
    setSteps([])
  };

  const toggleSteps = () => {
    setFlagCloseTutorial(false);
    if (steps.length < 1) {
      launchIntro(false)
    } else {
      setStepsEnabled(prevStepsEnabled => !prevStepsEnabled);
    }
  };

  const options = {
    tooltipClass: "customTooltip",
    showButtons: false,
    // hideNext: true,
    exitOnOverlayClick: true,
    // exitOnEsc: false,
    showBullets: true,
    // doneLabel: false,
    keyboardNavigation: false,
    skipLabel: `
      <a href="#" class="close-intro" onclick='window[\"skipTutorialFinalization\"]()'>
      <span>Quitter le didacticiel</span>
      <div class="close-intro-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.5836 1.19444L1.19472 22.5833M22.5836 22.5833L1.19472 1.19444" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </a>`
  }

  const t = useContext(TranslationContext);
  const [familyModalIsOpen, setFamilyModalIsOpen] = useState(false);
  const [showNotifUpdate, setShowNotifUpdate] = useState(false);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const granitName = getAdditionalGraniteNames();
    setAdditionalGraniteNames(granitName)    
    const fetchAccessories = async () => {
      if (!props.configurator.accessories) {
        const accessories = await apiService.get('/api/catalog/accessories');
        props.configActions?.storeAccessories(accessories);
      }
    };

    const checkScroll = () => {
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const detailsHeight = document.getElementById("ConfigurationDetails")?.clientHeight;
      if (documentHeight > windowHeight) {
        setHeight(`${detailsHeight / 4}px`);
      }
      else {
        setHeight(null);
      }
    };

    window.addEventListener('resize', checkScroll);

    fetchAccessories();
    window.skipTutorialFinalization = skipTutorialFinalization;
    // launchIntro(true)
    return () => {
      window.skipTutorial = undefined;
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const launchIntro = (loadEvent=false) => {
    setFlagCloseTutorial(false);
    const {configIntro} = props.configurator
    const intro = loadEvent 
      ? configIntro.find(element => (element.step == "finalization" && element.views < 1)) 
      : configIntro.find(element => (element.step == "finalization"))
    
    if (intro) {
      const data = intro.data.map(dataItem => {
        const lastSegment = dataItem.intro.split(".").pop();
        dataItem.intro = t(lastSegment);
        return dataItem;
      }).sort((a, b) => {
        return a.order - b.order;
      });

      const familyUser = getStore().getState().user
      const member_id = familyUser && familyUser.id ? familyUser.id : null
      // if (loadEvent && member_id) props.configurationActions.addReadOnboarding(member_id, intro.id);
      if (member_id) props.configurationActions.addReadOnboarding(member_id, intro.id);
      setSteps(data)
    }
  }

  const configuration_ = getStore().getState().configurator.current.configuration;
  const [configuration, setConfiguration] = useState(configuration_);
  const monument = configuration.monument;
  const monumentComponents = getMonumentCategory();
  // const footstoneName = monument.footstone && monument.footstone.reference
  //   ? `GPG ${getFootstoneCode(monument.footstone.reference)}`
  //   : '';

  const frameGranite = (configuration.frame && configuration.frame.mode && configuration.frame.granite) || null;
  
  let footstoneName = isQuote(configuration)
    ? `GPG ${configuration.monument.reference}`
    : `GPG ${getFootstoneCode(monument.footstone.reference)}`

  if (monument.catalog && monument.catalog.name !== "") {
    footstoneName = monument.catalog.name
  }

  if(monument.category in monumentComponents){
    footstoneName = monumentComponents[monument.category];
  }

  let graniteNames = {};
  props.configurator.current.options.allgranites?.forEach(granite => {
    graniteNames[granite.reference] = t(`config3d_granit_${granite.reference}`) || granite.name
  });

  let graniteName = graniteNames[monument.graniteMain] + (monument.graniteSecondary && monument.graniteSecondary !== monument.graniteMain ? ` et ${graniteNames[monument.graniteSecondary]}` : '');
  const isFrame = monument.category === "SEM";
  const isVeneer = monument.category === "PLT";
  const isAccessory = monument.category === "ACC";
  const isMonument = !isFrame && !isVeneer && !isAccessory;
  if(!isMonument){
    graniteName = graniteNames[monument.graniteMain]
  }
  switch (monument.category){
    case "SEM":
      graniteName = configuration.frame.granite? graniteNames[frameGranite]:graniteNames[monument.graniteMain]
      break;
    case "PLT":
      graniteName = configuration.veneer.granite? graniteNames[configuration.veneer.granite]:graniteNames[monument.graniteMain]
      break;
    default:
  }

  const getAdditionalGraniteNames = () => {
    const veneerDimensions = configuration?.veneer?.dimensions 
    const veneerGranite = (
      !_.some(
        _.map([
            veneerDimensions?.front || {},
            veneerDimensions?.back || {},
            veneerDimensions?.left || {},
            veneerDimensions?.right || {}
          ], ({length, thickness, left, right, back, front}) => (
            !!length && !!thickness && (!!left || !!right || !!back || !!front)
          )
        )
      )
    ) ? configuration?.veneer?.granite : null;

    const monument = configuration.monument
    const frameGranite = (configuration.frame && configuration.frame.mode && configuration.frame.granite) || null;
    let additionalGraniteNames = '';
    if (frameGranite && frameGranite !== monument.graniteMain &&
      frameGranite !== monument.graniteSecondary) {
      additionalGraniteNames += 'Semelle ';
      if (veneerGranite && veneerGranite === frameGranite) {
        additionalGraniteNames += 'et placage ';
      }
      additionalGraniteNames += 'en ' + graniteNames[frameGranite];
      if (veneerGranite && veneerGranite !== frameGranite) {
        additionalGraniteNames += ', Placage en ' + graniteNames[veneerGranite];
      }
    } else {
      if (veneerGranite && veneerGranite !== monument.graniteMain) {
        additionalGraniteNames += 'Placage en ' + graniteNames[veneerGranite];
      }
    }
    return additionalGraniteNames;
  }
  
  const showNotif = () => {
    setShowNotifUpdate(true);
  }

  const openFamilyModal = () => {
    setFamilyModalIsOpen(true);
  };

  const closeFamilyModal = () => {
    setFamilyModalIsOpen(false);
    if (showNotifUpdate) {
      props.actions.updateFamilyInfo();
      onChange("project.customer", configuration_.project.customer)
      setShowNotifUpdate(false);
    }
  };

  const onChange = async (path, value) => {
    await new Promise((resolve) => {
      setConfiguration((prevConfig) => ({
        ...prevConfig,
        ..._.set({}, path, value),
      }), resolve);
    });
  };
  
  const onSave = async path => {
    onChange();
    const globalPath = path.indexOf('family') !== -1 ? 'family.contact' : 'project';
    if (_.get(configuration_, globalPath) !== _.get(configuration, globalPath)) {
      const value = _.get(configuration, globalPath);
      props.actions.updatePath(globalPath, value);
    }
  };
  
  return (
    <div className="ConfigurationCompletion row d-flex" style={{
      height: "98%"
    }}>
      {
        steps.length > 0 && <Steps
          ref={stepsRef}
          options={options}
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onBeforeExit={onBeforeExit}
          onExit = {onExit}
        />
      }
      <div className="col-md-8 col-sm-12">
        <div className='row' style={{ height:"85%" }}>
          <div className='col'>
            <ConfigurationIsoView config={configuration}  frame={monument.category === "SEM"} />
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col'>
            <div className='action-buttons-container d-flex justify-content-center'>
              <View3D/>
              <Flowers/>
            </div>
          </div>
        </div>

        <div className='row d-flex justify-content-center align-items-center' style={{width: "100%"}}>
          <FooterWithRouter footerLinks={props.footerLinks} withoutNavigationButtons={true}>
            <DefaultFooterTitle accessories={props.configurator.accessories} configuration={configuration}/>
            {!isAccessory && <p className="Granite">{graniteName}
            {isMonument && additionalGraniteNames && (<small> ({additionalGraniteNames})</small>)}</p>}
          </FooterWithRouter>
        </div>
      </div>

      <div className="col-md-4 col-sm-12 ConfigurationDetails d-flex flex-column" id="ConfigurationDetails">
        <div className='row'>
          <div className='col'>
            <div className='d-flex align-items-center mt-4'>
              <div className='footstone-wrapper flex-grow-1'>
                {monument.kind === "MON" && <>
                  <span>{ t('config3d_finalize_title_your') || "Votre" } </span>
                  <span title={monument.reference}>{footstoneName}</span>
                </>} 
              </div>
              <Documents isFinalizationStep={true}/>
              <Share/>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className="form-group project-wrapper">
              <div className='d-flex align-items-center mb-3'>
                <div className='project-reference'>
                  Référence du projet *</div>
                <button 
                  onClick={openFamilyModal} 
                  className="btn-config-secondary config-button-background text-dark config-button-hover btn-icon">
                  <span className="edit-icon icon icon-large"></span>
                </button>
                <Modal
                  className="custom-modal"
                  isOpen={familyModalIsOpen}
                  onRequestClose={closeFamilyModal}
                >
                  <ProjectMetaInfoEditor 
                    configuration={props.configuration} 
                    onModalClose={closeFamilyModal} 
                    showNotif={showNotif} />
                </Modal>
              </div>
              <div className={cn({error: !_.get(configuration, "project.customer")}, "input-wrapper")}>
                <MetaInfoInput
                  configuration={configuration}
                  configPath="project.customer"
                  placeholder={ t("config3d_finalize_project-info_family-project") }
                  className="Customer custom-input-field"
                  onChange={onChange}
                  onSave={onSave}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className='row flex-grow-1 detailsList' style={{
          overflow: "auto",
          minHeight: "6.25rem",
        }}>
          <div className='col' style={height && {
            height: height,
          }}>
            <Lines {...props} /> 
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <BottomBar {...props}/>
          </div>
        </div>
        
        <div className='row'>
          <div className='col'>
            {!isQuote(configuration) && <div className="Actions"><PlaceOrderModalButton /></div>}
          </div>
        </div>
      </div>

      {/* <div className="left-buttons">
        <div className="left-buttons-container">
          <Documents isFinalizationStep={true}/>
          <Share/>
          <hr />
          {!isQuote(configuration) && <CreateVariantButton configuration={configuration}/> }
          <div className="btn-wrapper">
            <div className="btn-config">
              <button 
                onClick={() => window.open(`${process.env.REACT_APP_EXTRANET}/pro-space/configurations?archived=0&search=`+configuration.reference, "_blank")} 
                className="text-decoration-none SquareButton btn-config-secondary text-dark secondary3 secondary3-hover">
                <span className="box-arrow-in-left-icon icon icon-large"></span>
                <div> { t('config3d_finalize_button_quotes') || "Mes devis" } </div>
              </button>
            </div>
          </div>

          {introIsAvailable("finalization") &&
            <div className='d-flex flex-column align-items-center'>
              <span onClick={() => toggleSteps()} className="open-tutorial question-circle-icon icon icon-large mb-2"></span>
              <span className="info-tutorial text-dark ">visite guidée de la page</span>
            </div>
          }
        </div>
      </div> */}
    </div>
  )
}


export function Lines(props) {
  const { isDetailsModal } = props;
  const monumentComponents = getMonumentCategory()
  const monumentNotFound = props.configuration.monument && props.configuration.monument.reference === ""
  const isMonumentComponent = (props.configuration.monument.category in monumentComponents)
  return (
    <div className="Container" id={isDetailsModal && "detailsContainer"}>
      {!isMonumentComponent && !monumentNotFound && <MonumentLine {...props} isDetailsModal={isDetailsModal} />}
      {(!isMonumentComponent || props.configuration.monument.category === "SEM") && <FrameLine {...props} isDetailsModal={isDetailsModal} />}
      <VeneerLine {...props} isDetailsModal={isDetailsModal} />
      {props.configuration.patterns
        .filter(pattern => !pattern.imprint)
        .map((item, index) => (
          <PatternLine index={index} key={index} item={item} {...props} isDetailsModal={isDetailsModal} />
        ))
      }
      {props.configuration.patterns
        .filter(pattern => pattern.imprint)
        .map((item, index) => (
          <PatternLine index={index} key={index} item={item} {...props} isDetailsModal={isDetailsModal}/>
        ))
      }
      {props.configuration.engravings
          // .filter(engraving => !!(engraving.text || '').replace(/\s+/, '')) // on laisse afficher les gravures vides pour pouvoir les retirer manuellement
          .filter(engraving => !engraving.reservation) // les reservations ne sont pas listée dans le devis
          .map((item, index) => (
            <EngravingLine index={index} key={index} item={item} {...props} isDetailsModal={isDetailsModal}/>
          ))
      }
      {<ReservationLine {...props} isDetailsModal={isDetailsModal} />}
      {props.configuration.accessories.map((item, index) => (
          <AccessoryLine index={index} key={index} item={item} {...props} isDetailsModal={isDetailsModal}/>
        ))
      }
      {(props.configuration.patterns.filter(pattern => !pattern.imprint).length>0 || props.configuration.engravings.filter(engraving => !engraving.reservation).length>0) &&
        <AddPresGrav {...props} isDetailsModal={isDetailsModal}/>
      }
      {<PackageLine  {...props} isDetailsModal={isDetailsModal}/>}
      {props.configuration.additional && props.configuration.additional.lines.map((item, index) => (
          <AdditionalLineForm index={index} key={index} {...props} isDetailsModal={isDetailsModal}/>
        ))
      }
    </div>
  )
}

function ReservationLine({actions, configuration,options, isDetailsModal}) {
  const reservations = configuration.engravings.filter(reserve => reserve.reservation)
  const face = reservations.length > 0 ? getFace(reservations[0].face,options) : null;

  return (
    <>
    { reservations.length > 0 &&
    <CompletionLine
      editItem={!configurationOrdered(configuration)  && !isConfigRequested() && goToStepOnClick(configuration, 'motifs-gravures/' + formatFaceToId(face), reservations[0].id)}
      removeItem={!configurationOrdered(configuration) && !isConfigRequested() ?() => actions.removeReservations():false}
      isDetailsModal={isDetailsModal}
      >
      <p><span className={isDetailsModal && "font-weight-bold"}>Réservation(s) pour Gravure future </span><br />
      {reservations
          .map((item, index) => (
            <span key={index}>
              {item.text}
            </span>
          ))
      }
      </p>
    </CompletionLine>
    }
    </>
  )
}

export const ConfiguratorCompletion = connect(
  state => ({
    canChangePrice: true,
    user: state.user, // Permet que le composant se refresh quand l'user est chargé.
    configurator: state.configurator,
    ui: state.ui,
    ...state.configurator.current
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
    configurationActions: bindActionCreators(configurationActions, dispatch),
  }))(ConfiguratorCompletionComponent);
  
function MonumentLine({configuration, options, actions, canChangePrice, isDetailsModal}) {
  const monument = configuration.monument;
  const layout = _.find(options.layouts, {reference: monument.layout});
  const layoutName = layout && layout.name;
  return (
    <CompletionLine
      image={displayGranitConfigImage(configuration,configuration.monument.graniteMain,"monument")}
      price={monument.price}
      editItem={!isQuote(configuration) && !configurationOrdered(configuration) && !isConfigRequested() && goToStepOnClick(configuration, 'monument') }
      priceChange={canChangePrice && ((p) => actions.updatePrice('monument', p))}
      isDetailsModal={isDetailsModal}>
      <p>
        <span className={isDetailsModal && "font-weight-bold"}>
          {formatMonumentName(monument)} <br />
        </span>
        {/* Granit : <GraniteNameLink
          main={monument.graniteMain}
          secondary={monument.graniteSecondary}
          configuration={configuration}
          options ={options}
        /> */}
      </p>
      {isDetailsModal && (
        <>
          {monument.description && (<p>{monument.description}</p>)}
          {layoutName && <p>Recette : {layoutName}</p>}
          {monument.base && _.padStart(monument.base.height, 2, '0') && (
            <p>
              {isMonumentTombale(monument) ? 'Épaisseur' : 'Soubassement'} : {_.padStart(monument.base.height, 2, '0')}HT
            </p>
          )}
          <ItemDetails item={monument} />
          <p>Référence : {formatMonumentReference(monument)}</p>
          <ItemWeight item={monument} />
        </>
      )}
      {/* {layoutName && (<p>Recette : {layoutName}</p>)}
      {monument.base && _.padStart(monument.base.height, 2, '0') && (
        <p>{isMonumentTombale(monument) ? 'Épaisseur' : 'Soubassement'} : {_.padStart(monument.base.height, 2, '0')}HT</p> || null
      )}
      <ItemDetails item={monument}/>
      <p>Référence : {formatMonumentReference(monument)}</p>
      <ItemWeight item={monument}/> */}
    </CompletionLine>
  )
}
function ItemDetails({item}) {
  if (item.details){
    const infos = item.details.split(" / ");
    const tombaleInfo = infos.find(info => info.includes('Tb'));
    const steleInfo = infos.find(info => info.includes('St'));

    return (
      <div>
        {tombaleInfo && <p>Tombale : {tombaleInfo.replace('Tb','')}</p>}
        {steleInfo && <p>Stèle : {steleInfo.replace('St','')}</p>}
      </div>
    );
  }
  return null
}

function GraniteNameLink({main, secondary, configuration,options}) {
  return (
    <span>
      &nbsp;
      <Link to={`/configuration/${configuration.reference}/granit`}>
        {getGraniteName(main,configuration,options) + (secondary ? ` et ${getGraniteName(secondary,configuration,options)}` : '')}
      </Link>
    </span>
  );
}

function ItemWeight({item, categ}) {
  const qty = item.quantity ? item.quantity : 1
  let showTotal = item.reference && item.reference.startsWith('A') && categ==='ACC' ? true : false;
  showTotal = (categ==="SEM")? true : false
  return ((parseFloat(item.weight) && <p>Poids {showTotal ? "total" : ""}: {item.weight} Kg</p>) || null)
}

function Dimensions({frame}){
  if (!frame.dimensions) return "";
  const {front,back,left,right} = frame.dimensions;
  const height = frame.heights.join('/');
  let text = <div className="FrameDimensions"><p >
      <span> {`Pièce Avant: ${front.width} x ${front.depth} x ${height}`}</span>
      <span> {`Pièce Arrière: ${back.width} x ${back.depth} x ${height}`}</span>
    </p><p>
      <span> {`Pièce Gauche: ${left.width} x ${left.depth} x ${height}`}</span>
      <span> {`Pièce Droite: ${right.width} x ${right.depth} x ${height}`}</span>
    </p></div>
  return text
}

function FrameLine({configuration, options, actions, canChangePrice, isDetailsModal}) {
  const frame = configuration.frame;
  if (isQuote(configuration) && frame && frame.description){
    // Cas particulier des devis où n' a que la description et le prix avec un affichage d'image particulier
    // où on voit tout le monument avec le frame en highlight car un seul KDR pour tout
    return (
      <CompletionLine
        image={displayGranitConfigImage(configuration,configuration.monument.graniteMain,"frame")}
        price={frame.price} editItem={false} removeItem={false}
        priceChange={canChangePrice && ((p) => actions.updatePrice('frame', p))}
        isDetailsModal={isDetailsModal}>
        <div dangerouslySetInnerHTML={{__html: frame.description}}/>
      </CompletionLine>
    )
  }

  if (!frame || !frame.mode) {
    return null;
  }

  let granite = frame.granite || configuration.monument.graniteMain;
  const kind = _.find(options.frames, {kind: frame.kind}).label;
  const bedding = frame.bedding ? frame.bedding : DEFAULT_BEDDING;
  const backDepth = frame.dimensions ? frame.dimensions.back.depth - bedding: 0;
  const layout =_.find(getStore().getState().configurator.current.options.layouts, {reference: configuration.monument.layout});
  const layoutName = layout && layout.name;
  const paramLayout = configuration.monument.layout
  const configId = configuration.reference

  return (
    // image={imgs.frame.iso.thumbnail(frame.kind, granite)}
    <CompletionLine
      image={imgs.frame.config(configId, frame.kind, "thumbnail", granite,paramLayout,frame.layout)}
      price={frame.price}
      editItem={!configurationOrdered(configuration) && !isConfigRequested() && goToStepOnClick(configuration, 'monument', FrameOptionPanelId)}
      showQuantity = {true}
      removeItem={configuration.monument.category !== "SEM" && !isConfigRequested()? !configurationOrdered(configuration) ? () => actions.removeFrame():false : false}
      priceChange={canChangePrice && ((p) => actions.updatePrice('frame', p))}
      isDetailsModal={isDetailsModal}>
      <p>
        <span className={isDetailsModal && "font-weight-bold"}> Semelle {kind} {formatFrame(frame)} </span> <br />
        {/* Granit : <GraniteNameLink main={granite} configuration={configuration} options={options}/> */}
      </p>
      {/* && frame.mode ==='custom' */}
      {isDetailsModal && (
        <>
          {(configuration.monument.category === "SEM" && layoutName ) && <p>Recette : {layoutName}</p>}
          {frame.frontDepth && backDepth? frame.frontDepth === backDepth 
            ? <p>{`Vue avant de ${frame.frontDepth} cm centrée`}</p>
            : <p>{`Vue avant de ${frame.frontDepth} cm décentrée`}</p> : null}
          <p>
            {frame.layout === 'parpaing' ? `Avec Parpaing Arrière - lit de pose de ${bedding} cm` : `Avec Closoir Arrière - lit de pose de ${bedding} cm`}
          </p>
          <Dimensions frame ={frame}/>
          <p>Référence : {frame.reference}</p>
          <ItemWeight item={frame} categ={configuration.monument.category}/>
          {isFrameDimensionsTooBig() && <MessageDimensionsTooBig/>}
        </>
      )}
    </CompletionLine>
  )
}

function VeneerLine({configuration, actions, canChangePrice,options, isDetailsModal}) {
  const veneer = configuration.veneer;
  if (!veneer) return null;
  if (isQuote(configuration) && veneer && veneer.description){
    // Cas particulier des devis où n' a que la description et le prix avec un affichage d'image particulier
    // où on voit tout le monument avec le placage en highlight car un seul KDR pour tout
    return (
      <CompletionLine
        image={displayGranitConfigImage(configuration,configuration.monument.graniteMain,"veneer")}
        price={veneer.price} editItem={false} removeItem={false}
        priceChange={canChangePrice && ((p) => actions.updatePrice('veneer', p))}
        isDetailsModal={isDetailsModal}
        >
        <div dangerouslySetInnerHTML={{__html: veneer.description}}/>
      </CompletionLine>
    )
  }
  const {front = {}, back = {}, left = {}, right = {}} = (veneer.dimensions || {});

  if (!_.some(_.map([front, back, left, right], ({length, thickness, left, right, back, front}) => (
        !!length && !!thickness && (!!left || !!right || !!back || !!front)
      )
    ))) {
    return null;
  }
   let disposition = "";
   [front, back, left, right].map(item => disposition += item.length ? "1" :"0");
   let mesure = "";
   [front, back].map(item => mesure += item.left +"" +item.right);
  
  return (
    <CompletionLine
      image={imgs.veneer.config(configuration.reference,"thumbnail",veneer.granite,disposition,mesure)}
      price={veneer.price}
      editItem={!configurationOrdered(configuration) && !isConfigRequested() && goToStepOnClick(configuration, 'monument', VeneerOptionPanelId)}
      removeItem={configuration.monument.category !== "PLT" && !isConfigRequested() ? !configurationOrdered(configuration) ?() => actions.removeVeneer():false : false}
      priceChange={canChangePrice && ((p) => actions.updatePrice('veneer', p))}
      isDetailsModal={isDetailsModal}>
      <p>
        <span className={isDetailsModal && "font-weight-bold"}>Placage</span><br />
        {/* Granit : <GraniteNameLink main={veneer.granite} configuration={configuration} options={options}/>  */}
      </p>
      {isDetailsModal && (
        <>
          {(front.length &&
          (<p>Plinthe Avant de {front.length}
            &nbsp;- Ht Ghe {front.left}
            &nbsp;- Ht Drte {front.right}
            &nbsp;- Épais. {front.thickness}
            {(front.length > 230 && (<span> en 2 éléments</span>))}
          </p>)) || false}
          {(back.length &&
          (<p>Plinthe Arrière de {back.length}
            &nbsp;- Ht Ghe {back.left}
            &nbsp;- Ht Drte {back.right}
            &nbsp;- Épais. {back.thickness}
            {back.length > 230 && (<span> en 2 éléments</span>)}
          </p>)) || false}
          {(left.length &&
          (<p>Plinthe Gauche de {left.length}
            &nbsp;- Ht Av {left.front}
            &nbsp;- Ht Arr {left.back}
            &nbsp;- Épais. {left.thickness}
            {left.length > 230 && (<span> en 2 éléments</span>)}
          </p>)) || false}
          {(right.length &&
          (<p>Plinthe Droite de {right.length}
            &nbsp;- Ht Av {right.front}
            &nbsp;- Ht Arr {right.back}
            &nbsp;- Épais. {right.thickness}
            {right.length > 230 && (<span> en 2 éléments</span>)}
          </p>)) || false}
          {veneer.layout && <p>Implantation : {veneer.layout}</p>}
          <ItemWeight item={veneer}/>
          {isVeneerDimensionsTooBig() && <MessageDimensionsTooBig/>}
        </>
      )}
    </CompletionLine>
  );

}

function EngravingLine({item, index, actions, configuration, canChangePrice,options,user, isDetailsModal}) {
  const face = getFace(item.face,options);
  const countChars = (item.text || "").replace(/[\s\-,\.]/gi, "").length;
  let showZero = false
  if (countChars === 0) {
    showZero = true
  }
  return (
    <CompletionLine
      image={imgs.engravings(item,user)}
      price={item.price}
      priceChange={canChangePrice && ((p) => actions.updatePrice('engravings.' + index, p))}
      editItem={!configurationOrdered(configuration)  && !isConfigRequested() && goToStepOnClick(configuration, 'motifs-gravures/' + formatFaceToId(face), item.id)}
      removeItem={!configurationOrdered(configuration) && !isConfigRequested()?() => actions.removeItem('engravings', index):false}
      showZero={showZero}
      showZeroBuy = {true}
      isDetailsModal={isDetailsModal}>
      <p>
        <span className={isDetailsModal && "font-weight-bold"}>{formatEngravingName(item)}</span> {!isDetailsModal && <span><br />{item.text.replace(/\r?\n/ig, ' / ')}</span>}
      </p>
      {isDetailsModal && (
        <>
          <p>{getAndFormatFace(item.face,options)}</p>
          <p>{item.text.replace(/\r?\n/ig, ' / ')}</p>
          <p>Police {item.font.family} en {item.font.size}mm</p>
          <p><em>Texte : {item.text.replace(/\r?\n/ig, ' / ')}</em></p>
        </>
      )}
    </CompletionLine>
  )

}

function PatternLine({item, patterns, index, actions, configuration, canChangePrice,options, isDetailsModal}) {
  const itemDef = _.find(patterns, {reference: item.reference}) || {};
  const patternconfig = _.find(configuration.patterns, {id: item.id});
  const patternSize = getPatternSize(patternconfig,itemDef);
  const removeItem = !configurationOrdered(configuration) && !isConfigRequested() ? () => actions.removeItem('patterns', index):false;
  return (
    <CompletionLine
      image={imgs.pattern(item.reference, 'drawing')} price={item.price}
      priceChange={canChangePrice && ((p) => actions.updatePrice('patterns.' + index, p))}
      editItem={!item.required && !configurationOrdered(configuration) && !isConfigRequested() &&
      goToStepOnClick(configuration, 'motifs-gravures/' + formatFaceToId(getFace(item.face,options)), item.id)}
      removeItem={!item.required && !isConfigRequested() && removeItem}
      imprint ={item.imprint}
      isDetailsModal={isDetailsModal}>
      <p><span className={isDetailsModal && "font-weight-bold"}>
        {
          item.required ? (
            `${formatQuantity(item.quantity)}${itemDef.kind} ${itemDef.reference} obligatoire`
          ) : (
            `${formatQuantity(item.quantity)}${itemDef.kind} ${itemDef.reference} sur ${getAndFormatFace(item.face,options)}`
          )
        }
      </span></p>
      {isDetailsModal && (
        <>
          <p><em>{itemDef.description}</em></p>
          <p>{patternSize && `Dimensions: ${patternSize.height} x ${patternSize.width} cm`}</p>
        </>
      )}
    </CompletionLine>
  )

}

function AccessoryLine({item, options, accessories, configuration, index, actions, canChangePrice, configurator, isDetailsModal}) {
  let itemDef = _.find(accessories, {reference: item.reference})
  const standard = !!itemDef;
  if (!itemDef) {itemDef={reference:item.reference,kind:"",description:item.details}}
  const granite = item.granite || configuration.monument.graniteMain;

  if (item.reference.startsWith("ACI") && (configurator && configurator.accessories)) {
    // get the accessory granites
    const accessoryGranites = _.find(configurator.accessories, {reference: item.reference}).granites;
    
    // add the accessory granites to options.granites
    options.granites = _.concat(options.granites, accessoryGranites);
  }
  return (
    <CompletionLine
      image={displayAcccessoryConfigImage(configuration,item, granite)}
      price={item.price}
      priceChange={canChangePrice && ((p) => actions.updatePrice('accessories.' + index, p))}
      editItem={!configurationOrdered(configuration) && !isConfigRequested() && goToStepOnClick(configuration, 'accessoires', item.id)}
      removeItem={(configuration.monument.category !== "ACC" || configuration.accessories.length>1) && !isConfigRequested() ? !configurationOrdered(configuration)? () => actions.removeItem('accessories', index):false : false}
      accessory={item}
      showQuantity = {true}
      isDetailsModal={isDetailsModal}>
      {standard && <p><span className={isDetailsModal && "font-weight-bold"}>
        {itemDef.kind} {itemDef.reference} en {getGraniteName(item.granite, configuration, options)}
      </span></p>}
      {isDetailsModal && (
        <>
          <p><em>{itemDef.description}</em></p>
          <p><em>{item.details}</em></p>
          <ItemWeight item={item} categ={configuration.monument.category}/>
        </>
      )}
      
      {(configuration.monument.category === "ACC" && isFromStock(configuration)) && 
            <span key="stockAccMsg" style={{color: "#D4321D", fontStyle: "italic", marginLeft: "15px"}}>Sous réserve de disponibilité en stock</span>}
    </CompletionLine>
  )
}

function AddPresGrav({configuration, isDetailsModal}) {
  const presgrav = configuration.additional.lines.filter(line => line.title == "prestgrav")
  
  if (presgrav.length > 0 ) {
    let presgrav_price = presgrav[0].price
    return  <CompletionLine image={imagePresgrav} price={presgrav_price} priceChange={false} 
                         removeItem={false}className="Presgrav" isDetailsModal={isDetailsModal}> 
              <p><span className={isDetailsModal && "font-weight-bold"}>{presgrav[0].description}</span></p>
          </CompletionLine>
  }
  return ''
}
function PackageLine({configuration,user, isDetailsModal}){ // Frais de pose du monument
  const price = isQuote(configuration) ? configuration.price.package && configuration.displayPackagePrice ? configuration.price.package : null
                                       : totalPricePackageToDisplay(user,configuration);
  const displayPackage =  _.get(user,"identity.organization.packageText");
  if ((!price) || configuration.monument.category ==='ACC') return <div/>
  return  <CompletionLine image={imagePack} price={{buying:0,selling:price}} priceChange={false}
                         removeItem={false} className="Package" isDetailsModal={isDetailsModal}>
              <p><span className={isDetailsModal && "font-weight-bold"}>{displayPackage}</span></p>
          </CompletionLine>
}



function MessageDimensionsTooBig() {
  return (
    <div className="DimensionsTooBig">
      <br/>
      <i className="material-icons">&#xE002;</i>
      <p>{messageDimensionsTooBig}</p>
    </div>
  )
}

function BottomBar(props) {
  const { pledg } = config;
  const t = useContext(TranslationContext);
  const { showSellingPrices } = props.ui;
  const { simulatePaymentSchedule } = props.configurator || null
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [totalAmount, setTotalAmount] =  useState(null);

  const openDetailsModal = () => {
    setDetailsModalIsOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalIsOpen(false);
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  useEffect(() => {
    const fetchSimulatePayment = async () => {
      const currentDate = getCurrentDate();
      const totAmount = parseInt(props.configurator.current.configuration.price.custom)
      setTotalAmount(totAmount)
      props.configurationActions.simulatePaymentSchedule((totAmount * 100), currentDate);
    };
    fetchSimulatePayment();
  }, [props.configurator.current.configuration.price.custom]);
  
  const category = props.configuration.monument.category;
  const configId = props.configuration.reference;
  return (
    <div className="OrderFooter pb-3">
      {category ==='ACC' && <div className='row mb-4'>
        <div className='col d-flex'>
          <NavLink className="addAccessorie button text-dark button-secondary button-small flex-grow-1" to = {'/configuration/'+configId+'/monument'}>
            <span className="plus-icon icon-medium text-dark-icon" ></span>
            <span>Ajouter un accessoire</span>
          </NavLink>
        </div>
      </div>}

      <div className='row mb-4'>
        <div className='col'>
          <div className='d-flex justify-content-between'>
            {/* <AddCustomLineButton {...props}/> */}
            {/* eslint-disable-next-line react/jsx-no-undef */}

            {/* <button onClick={!isConfigRequested() && addCustomLine} className={`AddCustomLine text-dark button-secondary button-small ${isConfigRequested() ? "disabled" : ""}`} disabled={isConfigRequested()}>
            <span className="plus-icon icon-medium text-dark-icon" ></span>
            <span>
              { t('config3d_finalize_footer_button_add-line') || "Ajouter une ligne" }
            </span>  
            </button> */}
            {/* <ProjectPaymentSettings/> */}

            {/* <button onClick={openDetailsModal} className="flex-grow-1 text-dark button-secondary button-small" style={{ flexBasis: "50%", marginLeft:"5px" }}>
              <span className="eye-icon icon-medium text-dark-icon"></span>
              <span>{ "Vue détaillée" }</span>
            </button>

            <Modal
              className="custom-modal" 
              isOpen={detailsModalIsOpen}
              onRequestClose={closeDetailsModal}>
              <ModalConfigurationDescription onRequestClose={closeDetailsModal} />
            </Modal> */}
          </div>
        </div>
      </div>

      <div className='row my-3'>
        <div className='col'>
          <Totals {...props} />
        </div>
      </div>

        {(showSellingPrices && (totalAmount < 10000) && userCan.isProfilPnf()) && (
          <div style={{height: "60px"}}>
          {/* <div className="row my-3">
            <div className='col'>
              <div className="lines">
                <div className="line"></div>
                <p>{t('config6d_finalize_pnf-or') || "Ou"}</p>
                <div className="line"></div>
              </div>
            </div>
          </div> */}
          
          <div className="simulate-payment-wrapper">
            <div className='d-flex' style={{flexDirection:'row-reverse', marginRight: "40px"}}>
              <div className="text-dark price">{simulatePaymentSchedule ? price(Math.ceil(simulatePaymentSchedule)) : '---'} €<small>{ t("config3d_finalize_details_vat") || "TTC" } </small>*</div>
              <span>
                <a onClick={() => window.open(pledg.dashboard_url, '_blank')} className='text-decoration-underline' style={{color: "#2563EB", cursor:"pointer"}}>
                  Paiement en 4 fois : 
                </a>
              </span>
            </div>
            <div className="info">
              {t('config5d_finalize_pnf-button_disclaimer') || "*hors éventuel coût du crédit"}
            </div>
          </div>


          {/* <div className="row simulate-payment-wrapper">
            <div className='col'>
              <button 
                onClick={() => window.open(pledg.dashboard_url, '_blank')}
                className="flex-grow-1 text-dark button-secondary button-small w-100"
              >
                <span className="font-weight-normal">
                {t('config3d_finalize_pnf-button_from') || "Dès"} <strong>{simulatePaymentSchedule ? price(Math.ceil(simulatePaymentSchedule)) : '---'}</strong> {t('config4d_finalize_pnf-button_instalments') || "par mois en 10 mois"}
                </span>
              </button>
              <span className="info">
                {t('config5d_finalize_pnf-button_disclaimer') || "*hors éventuel coût du crédit"}
              </span>
            </div>
          </div> */}
          </div>
        )}
    </div>
  )
}

function AddCustomLineButton({configuration, actions, uiActions}) {
  
  function addCustomLine() {
    uiActions.setEditedLineIndex(configuration.additional.lines.length);
    actions.pushItem('additional.lines', {
      title: '',
      description: '',
      price: {custom: 0}
    });
  }

  const t = useContext(TranslationContext);
  return (
     !configurationOrdered(configuration) && 
    // <button onClick={!isConfigRequested() && addCustomLine} className={`AddCustomLine ${isConfigRequested() ? "disabled" : ""}`} disabled={isConfigRequested()}>
    //   <span>Ajouter une ligne</span>
    //   <MediaQuery minWidth={576}>
    //     <i className="icon material-icons">&#xE146;</i>
    //   </MediaQuery>
    //   <MediaQuery maxWidth={576}>
    //     <i className="icon material-icons">add</i>
    //   </MediaQuery>
    // </button>

    <button onClick={!isConfigRequested() && addCustomLine} className={`AddCustomLine text-dark button-secondary button-small flex-grow-1 ${isConfigRequested() ? "disabled" : ""}`} disabled={isConfigRequested()} style={{ flexBasis:"50%", marginRight:"5px" }}>
      <span className="plus-icon icon-medium text-dark-icon" ></span>
      <span>
        { t('config3d_finalize_footer_button_add-line') || "Ajouter une ligne" }
      </span>  
    </button>
  )
}



function CompletionFooter({configuration}) {
  const createdAt = moment(configuration.createdAt).format('DD/MM/YYYY')
  const [popUp, setPopUp] = useState(false)
  const wrapperRef = useRef(null);
  const confQuotePath = configuration && !configuration.quoteId ? 'pro-space/configurations' : 'pro-space/quotes';
  useOutsideClick(wrapperRef);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPopUp(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const monumentComponents = getMonumentCategory()
  const isMonumentComponent = configuration.monument.category in monumentComponents
  let previousLink;
  if (isMonumentComponent) {
    if (configuration.monument.category === 'ACC'){
      previousLink = `/configuration/${configuration.reference}/accessoires`;
    }else{
      previousLink = `/configuration/${configuration.reference}/granit`;
    }
    
  } else {
    previousLink  = `/configuration/${configuration.reference}/accessoires`;
  }

  const svgValid = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#22C55E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2445 5.42085C16.4723 5.64866 16.4723 6.01801 16.2445 6.24581L7.91118 14.5791C7.68337 14.807 7.31403 14.807 7.08622 14.5791L3.75289 11.2458C3.52508 11.018 3.52508 10.6487 3.75289 10.4209C3.98069 10.193 4.35004 10.193 4.57784 10.4209L7.4987 13.3417L15.4196 5.42085C15.6474 5.19305 16.0167 5.19305 16.2445 5.42085Z" fill="#EAEEF2"/>
    </svg>  
  `;

  const t = useContext(TranslationContext);

  return (
    <div ref={wrapperRef}>
      {popUp &&
      <div className="pop-up">
        <div className="Actions">
          <PlaceOrderModalButton/>
          {/* <Sclient conf={configuration}/> */}
          {(!configuration.status  || !configuration.status.validatedAt) && (
            <a
              className="SaveInfo"
              target="_blank"
              rel="noopener noreferrer"
              href={getReactAppExtranet("/"+confQuotePath+"?search=" + configuration.reference)}
            >
              <i className="material-icons">
                open_in_new
              </i>
              <span>Retrouvez-la dans {confQuotePath==='pro-space/configurations' ? 'Mes Configurations' : 'Mes Devis'}</span>
            </a>
          )}
          <CreateVariantButton configuration={configuration}/>
        </div>
      </div>
      }
      <section className='Footer'>
        <MediaQuery minWidth={576}>
        {/* <div className="PreviousStep">
          <Link to={previousLink}>
            <i className="icon material-icons">&#xE408;</i>
          </Link>
        </div> */}

        {!isQuote(configuration) && <>
          <div className="Actions"> <CreateVariantButton configuration={configuration}/> </div>
          {/* <div className="Actions"><Sclient conf={configuration}/></div> */}
        </>}

        {(!configuration.status  || !configuration.status.validatedAt) && (
          <div className="Actions">
          <a
            className="SaveInfo"
            target="_blank"
            rel="noopener noreferrer"
            href={getReactAppExtranet("/"+confQuotePath+"?search=" + configuration.reference)}
          >
            <span className='icon' dangerouslySetInnerHTML={{ __html: svgValid }} />
            <div className='d-flex flex-column align-items-baseline'>
              <strong>
                { t('config3d_finalize_footer_button_save_title') || "Configuration enregistrée" }
              </strong>
              <small>
                {/* Retrouvez-la dans {confQuotePath==='pro-space/configurations' ? 'Mes Configurations' : 'Mes Devis'} */}
                { t('config3d_finalize_footer_button_save_subtitle') || "Rétrouvez-la dans Configurations" }
              </small>
            </div>
          </a>
          </div>
        )}

        <div className="Headline">
          <h1 className="FamilyName">{configuration.project.customer || t('config3d_finalize_footer_no-ref') }</h1>
          <p className="Details">
            <span className="creationInfo">Créée le {createdAt} </span>
            <span className="referenceInfo"> Réf. : CA{configuration.reference} </span>
          </p>
        </div>

        
        {!isQuote(configuration) && <>
          <div className="Actions"><Sclient conf={configuration}/></div>
          <div className="Actions"><PlaceOrderModalButton/></div>
          </>
        }
        </MediaQuery>
        <MediaQuery maxWidth={576}>
          {/* <div className="PreviousStep">
            <Link to={previousLink}>
              <i className="icon material-icons">&#xE408;</i>
            </Link>
          </div> */}
          <div className="FooterPanel">
            <div className="Headline">
              <h1 className="FamilyName">{configuration.project.customer || t('config3d_finalize_footer_no-ref') }</h1>
              <p className="Details">
                <span className="creationInfo">Créée le {createdAt} </span>
                <span className="referenceInfo"> Réf. : CA{configuration.reference} </span>
              </p>
            </div>
            <div className="PlusIcon">
              <span className="material-icons" onClick={() => setPopUp(!popUp)}>
                more_horiz
              </span>
              <div>Plus</div>
            </div>
          </div>
        </MediaQuery>
      </section>
    </div>
  )

}


const goToStepOnClick = (configuration, step, toHighlight) => () => {
  goToAndFocusItem('/configuration/' + configuration.reference + '/' + step, toHighlight);
}

import React, {useContext} from "react";
import { Link, withRouter } from "react-router-dom";
import MediaQuery from "react-responsive/src";
import { getFootstoneCode } from "../../../services/format.service";
import {getStore} from '../../../store';
import { getMonumentCategory } from "../../../services/utils.service";
import { Monument } from "../../monument/Monument";
import { TranslationContext } from "../../../context/TranslationContext";
import { isSmartphone } from '../../../services/domFunctions';

const Footer = ({ footerLinks, children, history, withoutNavigationButtons=false }) => {
  const kind = getStore().getState().configurator.current.configuration.monument.kind;
  const ismonumenComponent = ["SEM","PLT"].includes(kind);

  return (
    <section className={`Footer ${(withoutNavigationButtons || isSmartphone()) ? 'without-navigation-buttons' : ''}`}>
      <MediaQuery maxWidth={490}>
        <div className="Headline">{children}</div>
      </MediaQuery>

        {!withoutNavigationButtons && !isSmartphone() &&
          <div className="PreviousStep button-navigation" style={(ismonumenComponent && (!footerLinks.previousLink) )? {width:120,height:76} : {}}>
            {footerLinks && footerLinks.previousLink &&(
              <button onClick={()=>history.push(footerLinks.previousLink.link)} className="button-secondary button-medium text-dark">
                <span className="chevron-left-icon icon-medium text-dark-icon" ></span>
                <span>{footerLinks.previousLink.label}</span>  
              </button>
            )}
          </div>
        }

        <MediaQuery minWidth={490}>
          <div className={`Headline ${kind === "ACC" ? "acc-headstone" : ""}`}>{children}</div>
        </MediaQuery>

        {!withoutNavigationButtons && !isSmartphone() &&
          <div className="NextStep button-navigation">
            {footerLinks && footerLinks.nextLink && (
              <button onClick={()=>history.push(footerLinks.nextLink.link)} className="button-primary button-medium text-cta">
                <span>{footerLinks.nextLink.label}</span>  
                <span className="chevron-right-icon icon-medium text-cta-icon" ></span>
              </button>
            )}
          </div>
        }
    </section>
  );
};

const FooterWithRouter = withRouter(Footer);

function DefaultFooterTitle({ configuration, accessories }) {
  const monumentComponents = getMonumentCategory();
  const {frame, veneer} = configuration;
  const monument = configuration.monument,
    footstoneRef = monument.footstone && monument.footstone.reference;

  let footstoneName = footstoneRef ?`GPG ${getFootstoneCode(footstoneRef)}`:"";
  let monumentCata = false
  if (monument.catalog && monument.catalog.name !== "") {
    footstoneName = monument.catalog.name
    monumentCata = true
  }
  if(monument.category in monumentComponents){
    footstoneName = monumentComponents[monument.category];
    monument.description = "";
  }

  const t = useContext(TranslationContext);
  const headstoneName = monument.headstone && monument.headstone.reference && monument.headstone.reference!="000" ? `Stèle ${monument.headstone.reference}` : "";
  const myAccessories = configuration.accessories;
  let accesso = [];
  if (monument.kind ==='ACC'){
    myAccessories.forEach(accessory=>{
      const bibAccesso =accessories && accessories.filter(acc => acc.reference===accessory.reference);
      if (bibAccesso && bibAccesso.length>0){
        const reference = bibAccesso[0].reference
        const descript = bibAccesso[0].description;
        const granites = bibAccesso[0].granites;
        // const granite = granites ? granites.filter(grnt => grnt.reference===accessory.granite) : {};
        const granite = granites ? granites.filter(grnt => {
          if (grnt.reference === accessory.granite) {
            const modifiedName = t(`config3d_granit_${grnt.reference}`) || grnt.name;
            grnt.name = modifiedName
            return grnt;
          }
        }) : {};
        let nameGranit = ""
        if(accessory.granite){
          const accessorygranit = getStore().getState().configurator.current.options.granites.filter(granite=>granite.reference === accessory.granite)
          if(accessorygranit.length>0){
            const modifiedName = t(`config3d_granit_${accessorygranit[0].reference}`) || accessorygranit[0].name;
            nameGranit = modifiedName;
          }
        }
        let granitename = granite.length>0 ? granite[0].name : nameGranit
        let thisAcc = {reference,descript,granite:  granitename}
        accesso.push(thisAcc)
      }
    });
  }
  
  if (monument.kind === 'ACC') {
    return (
      <>
        {accesso.map((accessory, index) => {
          if (index < 3) {
            return (
              <React.Fragment key={index}>
                  <div className="accessory-name">
                    <span className='Footstone'>{accessory.descript} ({accessory.reference})</span> <span className="granite">{accessory.granite}</span>
                  </div>
                  
                {index + 1 !== myAccessories.length && index < 2 && (
                  <span className='separator'> / </span>
                )}
              </React.Fragment>
            );
          } else if (index === 3) {
            return <h1 key={index}>...</h1>;
          }
          return null;
        })}
      </>
    );
  } else if(monument.reference === "" && monument.kind && (monument.kind.includes("PLT") || monument.kind.includes("SEM"))) {
    const frameDescription = frame && frame.description && frame.description != 0 ? frame.description : ""
    const veneerDescription = veneer && veneer.description && veneer.description != 0 ? veneer.description : ""
    return (<h1>
      <span className="Description">
        {frameDescription && frameDescription !== "" && <span>- {frameDescription}</span>}
        {veneerDescription && veneerDescription !== "" && <span>- {veneerDescription}</span>}
      </span>
    </h1>);
  } else {
    return (<h1>
      <span className="Footstone">{footstoneName}</span>
      {headstoneName && monument.description != 0 && <span className="Headstone">{headstoneName}</span>}
      {monument.description && monument.description != 0 && <span className="Description">- {monument.description}</span>}
    </h1>);
  }
}

export { FooterWithRouter, DefaultFooterTitle };
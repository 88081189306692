import React, {useState, useContext, useEffect, useRef} from 'react';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FooterWithRouter, DefaultFooterTitle} from './components/Footer';
import {View3D} from './components/View3D';
import {Documents} from './components/Documents';
import {Details} from './completion/Details';
import {GlobalGranitePicker} from './granite/GlobalGranitePicker';
import {FrameGranitePicker} from './granite/FrameGranitePicker';
import {VeneerGranitePicker} from './granite/VeneerGranitePicker';
import {AccessoriesGranitePicker} from './granite/AccessoriesGranitePicker';
import {ConfigurationIsoView} from './components/ConfigurationIsoView';
import {UndoRedo} from "./components/UndoRedo";
import {Flowers} from './components/Flowers';
import configurator from '../../reducers/configurator';
import { TranslationContext } from "../../context/TranslationContext";
import * as configurationActions from "../../actions/configurationActions";
import {getStore} from '../../store';
import {Steps} from "intro.js-react";
import {introIsAvailable} from "../helpers/introHelpers"
import { isSmartphone } from '../../services/domFunctions';
import {isQuote} from '../../services/restrictedConfiguration';


function ConfiguratorGranitComponent({configurationActions, configIntro, configuration, granites,allgranites, footerLinks}) {
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [flagCloseTutorial, setFlagCloseTutorial] = useState(false);
  const stepsRef = useRef(null);

  useEffect(() => {
    window.skipTutorialGranit = skipTutorialGranit;
    // launchIntro(true)
    return () => {
      window.skipTutorial = undefined;
    };
  }, []);

  const onBeforeExit = (stepIndex) => {
    const nbSteps = steps.length - 1;
    if (nbSteps !== -1 && flagCloseTutorial === false) {
      if (stepIndex !== nbSteps) {
        const stepsRefValue = stepsRef.current.introJs;
        stepsRefValue.nextStep();
        return false;
      }
    }
  };

  const skipTutorialGranit = () => {
    setFlagCloseTutorial(true);
  };
  
  const onExit = () => {
    setSteps([])
  };

  const toggleStepsInModal = (modal) => {
    setFlagCloseTutorial(false);
    if (modal) {
      launchIntro(false, modal);
    }
  };

  const toggleSteps = () => {
    setFlagCloseTutorial(false);
    if (steps.length < 1) {
      launchIntro(false)
    } else {
      setStepsEnabled(prevStepsEnabled => !prevStepsEnabled);
    }
  };

  const options = {
    tooltipClass: "customTooltip",
    showButtons: false,
    // hideNext: true,
    exitOnOverlayClick: true,
    // exitOnEsc: false,
    showBullets: true,
    // doneLabel: false,
    keyboardNavigation: false,
    skipLabel: `
      <a href="#" class="close-intro" onclick='window[\"skipTutorialGranit\"]()'>
        <span>Quitter le didacticiel</span>
        <div class="close-intro-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5836 1.19444L1.19472 22.5833M22.5836 22.5833L1.19472 1.19444" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </a>`
  }

  const launchIntro = (loadEvent=false, modal=false) => {
    setFlagCloseTutorial(false);
    const intro = loadEvent
      ? modal ? configIntro.find(element => element.step === "granite" && element.is_modal && element.modal == modal && element.views < 1) : configIntro.find(element => element.step === "granite" && !element.is_modal && element.views < 1)
      : modal ? configIntro.find(element => element.step === "granite" && element.is_modal && element.modal == modal) : configIntro.find(element => element.step === "granite" && !element.is_modal)

    if (intro) {
      const data = intro.data.map(dataItem => {
        const lastSegment = dataItem.intro.split(".").pop();
        dataItem.intro = t(lastSegment);
        return dataItem;
      }).sort((a, b) => {
        return a.order - b.order;
      });
      
      const familyUser = getStore().getState().user
      const member_id = familyUser && familyUser.id ? familyUser.id : null
      // if (loadEvent && member_id) configurationActions.addReadOnboarding(member_id, intro.id);
      if (member_id) configurationActions.addReadOnboarding(member_id, intro.id);
      setSteps(data)
    }
  };

  let graniteNames = {};
  const t = useContext(TranslationContext);
  allgranites.forEach(granite => {
    graniteNames[granite.reference] = t(`config3d_granit_${granite.reference}`) || granite.name
  });

  const monument = configuration.monument;
  let graniteName = graniteNames[monument.graniteMain] +
    (monument.graniteSecondary && monument.graniteSecondary !== monument.graniteMain ? ` et ${graniteNames[monument.graniteSecondary]}` : '');

  let additionalGraniteNames = '';
  const frame = configuration.frame;
  const frameGranite = (frame && frame.mode && frame.granite) || null;

  // Si le placage a au moins une face valide, on affiche son granite.
  const veneer = configuration.veneer || {};
  const veneerDimensions = veneer.dimensions || {};
  const veneerGranite = (
    !_.some(
      _.map([
          veneerDimensions.front || {},
          veneerDimensions.back || {},
          veneerDimensions.left || {},
          veneerDimensions.right || {}
        ], ({length, thickness, left, right, back, front}) => (
          !!length && !!thickness && (!!left || !!right || !!back || !!front)
        )
      )
    )
  ) ? veneer.granite : null;

  if (frameGranite && frameGranite !== monument.graniteMain &&
    frameGranite !== monument.graniteSecondary) {
    additionalGraniteNames += 'Semelle ';
    if (veneerGranite && veneerGranite === frameGranite) {
      additionalGraniteNames += 'et placage ';
    }
    additionalGraniteNames += 'en ' + graniteNames[frameGranite];
    if (veneerGranite && veneerGranite !== frameGranite) {
      additionalGraniteNames += ', Placage en ' + graniteNames[veneerGranite];
    }
  } else {
    if (veneerGranite && veneerGranite !== monument.graniteMain) {
      additionalGraniteNames += 'Placage en ' + graniteNames[veneerGranite];
    }
  }

  const isFrame = monument.category === "SEM";
  const isVeneer = monument.category === "PLT";
  const isAccessory = monument.category === "ACC";
  const isQuoteWithoutMonument = isQuote(configuration) && (isFrame || isVeneer 
                                  || monument.category && (monument.category.includes("SEM") || monument.category.includes("PLT")))
  const isMonument = !isFrame && !isVeneer && !isAccessory && !isQuoteWithoutMonument;
  if(!isMonument){
    graniteName = graniteNames[monument.graniteMain]
  }
  switch (monument.category){
    case "SEM":
      graniteName = frame.granite? graniteNames[frameGranite]:graniteNames[monument.graniteMain]
      break;
    case "PLT":
      graniteName = veneer.granite? graniteNames[veneer.granite]:graniteNames[monument.graniteMain]
      break;
    default:
  }

  return (
    <div className="ConfigurationGranite">
      { steps.length > 0 && <Steps
          ref={stepsRef}
          options={options}
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onBeforeExit={onBeforeExit}
          onExit = {onExit}
        /> 
      }
      <ConfigurationIsoView config={configuration}  frame={monument.category === "SEM"}/>

      <div className="left-buttons">
        <div className="left-buttons-container">
          {(isMonument || isQuoteWithoutMonument)&& <GlobalGranitePicker
            toggleSteps={toggleStepsInModal}
            launchIntro={launchIntro}
            madalName="GlobalGranitePicker"
          />}
          {(isMonument || (isFrame && !isQuoteWithoutMonument)) && <FrameGranitePicker
            toggleSteps={toggleStepsInModal}
            launchIntro={launchIntro}
            madalName="FrameGranitePicker"
          />}
          {(isMonument || (isVeneer && !isQuoteWithoutMonument)) && <VeneerGranitePicker
            toggleSteps={toggleStepsInModal}
            launchIntro={launchIntro}
            madalName="VeneerGranitePicker"
          />}
          {(isMonument || (isAccessory && !isQuoteWithoutMonument)) && <AccessoriesGranitePicker
            toggleSteps={toggleStepsInModal}
            launchIntro={launchIntro}
            madalName="AccessoriesGranitePicker"
          />}

          {introIsAvailable("granite") &&
            <div className='d-flex flex-column align-items-center'>
              <span onClick={() => toggleSteps()} className="open-tutorial question-circle-icon icon icon-large mb-2"></span>
              <span className="info-tutorial text-dark ">visite guidée de la page</span>
            </div>
          }
        </div>
      </div>
     
     {/* {isSmartphone && isMonument && <div className="left-buttons"> <GlobalGranitePicker isSmartphone={isSmartphone}/></div>}
     {isSmartphone && isFrame && <div className="left-buttons"> <FrameGranitePicker isSmartphone={isSmartphone}/></div>}
     {isSmartphone &&  isVeneer && <div className="left-buttons"> <VeneerGranitePicker isSmartphone={isSmartphone}/></div>}
     {isSmartphone &&  isAccessory && <div className="left-buttons"> <AccessoriesGranitePicker isSmartphone={isSmartphone}/></div>} */}

      {!isSmartphone() && (
      <div className="right-buttons">
        <div className="right-buttons-container">
          <View3D/>
          <Documents/>
          <Details/>
          <UndoRedo />
          <Flowers />
        </div>
      </div>
      )}

      <FooterWithRouter footerLinks={footerLinks}>
        <DefaultFooterTitle configuration={configuration}/>
        {!isAccessory && <p className="Granite">{graniteName}
          {isMonument && additionalGraniteNames && (<small> ({additionalGraniteNames})</small>)}</p>}
          {isSmartphone() && (
            <div className="d-flex justify-content-around">
              <View3D />
              <Documents />
              <Details />
              <Flowers />
            </div>
          )}  
      </FooterWithRouter>
    </div>
  );
}

export const ConfiguratorGranit = connect(
  (state) => ({
    configuration: state.configurator.current.configuration,
    granites: state.configurator.current.options.granites,
    allgranites: state.configurator.current.options.allgranites,
    configIntro: state.configurator.configIntro,
  }),
  dispatch => ({
    configurationActions: bindActionCreators(configurationActions, dispatch),
  }))(ConfiguratorGranitComponent);

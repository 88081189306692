import * as _ from 'lodash';
import {engravingColors, engravingKinds} from '../constants';

export function price(price) {
  return (!price && price !== 0) ? '' : Number(price).toFixed(2).replace('.', ',')
    .replace(/(\d{1,3})?(\d{3})(?=[.,\s]|$)/ig, ' $1 $2');
}

export function formatFrame(frame) {
  const heights = frame.heights.join('/');
  return `${frame.width} x ${frame.depth} x ${heights}`;
}

export function formatFaceToId(face, drawing) {
  let accessory = ""
  if(face && face.piece && face.piece.name=="Accessoire"){
    accessory = face.piece.index
  }
  return face ? face.piece.reference + '_' + face.profile.reference + (drawing ? '_drawing' : '') + accessory : null;
}

export function formatFace(face) {
  return face ? face.piece.name + ' ' + face.profile.name : "";
}

export function formatMonumentName({layout, headstone, footstone, base, kind, catalog, alternativeMonumentName = null}) {
  let monumentKind;
  switch (kind) {
    case 'TBL':
      monumentKind = 'Tombale';
      break;
    case 'CIN':
      monumentKind = 'Cinéraire';
      break;
    case 'MON':
    default:
      monumentKind = 'Monument';
      break;
  }
  let footstoneName = alternativeMonumentName ? ` ${alternativeMonumentName}` : ' GPG ' + getFootstoneCode(footstone && footstone.reference)
  let monumentCata = false
  if (catalog && catalog.name !== "") {
    footstoneName = ' ' + catalog.name
    monumentCata = true
  }
  return monumentKind + footstoneName +
    (headstone && headstone.reference ? ' - Stèle ' + headstone.reference : '') +
    ' ';
}
export function formatMonumentReference({layout, headstone, footstone, base}) {
  if (!layout || !footstone) return ""
  return layout +
    footstone.reference +
    (headstone.reference ? _.padStart(base.height || '00', 2, '0') + headstone.reference : '' );
}

export function formatQuantity(quantity) {
  return quantity && quantity > 1 ? `(x${quantity}) ` : '';
}

export function formatEngravingName(item) {
  const kind = engravingKinds[item.font.kind];
  const color = engravingColors[item.font.color];
  if(item.font.color == "#dcdcdc"){
    return `${formatQuantity(item.quantity)}Gravure Lettres ${item.font.kind} ${color}`;
  }
  return `${formatQuantity(item.quantity)}Gravure Lettres ${kind} ${color} sur Poli`;
}

export function getFace({piece, profile},options) {
  return _.find(options.faces,
    (face) => face.piece.reference === piece && face.profile.reference === profile);
}

export function getAndFormatFace(refs,options) {
  return formatFace(getFace(refs,options))
}

export function getGraniteName(graniteRef,configuration,options) {
  if (!graniteRef) {
    graniteRef = configuration.monument.graniteMain;
  }
  const granites = options.allgranites;
  const granite = _.find(granites, {reference: graniteRef});
  return (granite && granite.name) || 'Granite invalide';
}

export function getFootstoneCode(footstone) {
  if (!footstone) return ''
  if (footstone.substr(0, 1) === 'T') {
    return footstone;
  } else {
    return `${Number(footstone.substr(0, 3))}${footstone.substr(3) !== 'A' ? ('/' + footstone.substr(3)) : ''}`;
  }
}




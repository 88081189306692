import React, {useContext} from 'react';
import { LazyImage } from '../../../components/LazyImage'
import cn from 'classnames'
import { displayGranitConfigImage } from '../../../services/image.fct.service'
import { getDesignTypeConfiguration } from '../../../services/utils.service'
import { TranslationContext } from "../../../context/TranslationContext";
/**
 * Composant affichant un granite en mode 'monument' 3D
 */
export function GranitePreviewCard({
  monument,
  pictos,
  config,
  granite,
  frameGraniteRef,
  frameMode,
  selected,
  disabledAccessory,
  disabledHeadstone,
  onSelectGranite,
  price,
  hoverInfo,
  large,
  lazy,
  pattern,
  showConfirm,
  onClickDisableGranite,
  fromFamily=false,
  color,
  hover
}) {
  if (!monument) return <div />
  const defMonument = {
    monument: {
      reference: monument.reference,
      graniteMain: granite.reference
    },
    frame: {
      mode: frameMode,
      granite: frameGraniteRef || granite.reference
    }
  }
  const size = large ? 'large' : 'thumbnail'
  const graniteSecondary = granite.gs ? granite.gs : null;
  const imageSrc = displayGranitConfigImage(
    config,
    granite.reference,
    false,
    graniteSecondary && graniteSecondary.reference,
    size,
    true,
    0,
    0,
    fromFamily
  )
  const t = useContext(TranslationContext);
  const designType = getDesignTypeConfiguration(config)
  const classType = (designType === 'A' ? 'cardA' : 'cardB')
  const lowResImg = displayGranitConfigImage(config, granite.reference,false, graniteSecondary && graniteSecondary.reference)
  const disabled = disabledAccessory || disabledHeadstone
  const hoverData = hoverInfo && hoverInfo(granite)
  let disabledMessage
  if (disabled) {
    if (disabledAccessory && disabledHeadstone) {
      disabledMessage = (
        <p>Ce granit n'est pas compatible avec les pièces sélectionnées.</p>
      )
    } else if (disabledAccessory) {
      disabledMessage = (
        <p>
          Ce granit n'est pas compatible avec certains des accessoires présents.
          <br />
          <br />
          Le choix de ce granit entraînera la suppression de ces accessoires.
        </p>
      )
    } else {
      disabledMessage = (
        <p>
          Ce granit n'est pas compatible avec la stèle sélectionnée.
          <br />
          <br />
          Modifiez votre stèle si vous souhaitez utiliser ce granit.
        </p>
      )
    }
  }
  return (
    <div
      className={cn([
        'GraniteCard',
        'GranitePreviewCard',
        { selected, disabled, disabledAccessory, showConfirm },
        classType
      ])}
      style={{"--f-main-color": color}}
    >
      <div
        className={cn(['GraniteCardContent'])}
        onClick={() => {
          if (!disabled) {
            onSelectGranite(granite)
          } else {
            onClickDisableGranite(granite)
          }
        }}
      >
        {lazy ? (
          <LazyImage
            src={imageSrc}
            lowResImage={lowResImg}
            hoverInfo={hoverData}
            customStyle={true}
          />
        ) : (
          <LazyImage src={imageSrc} lazy={false} hoverInfo={hoverData} fromFamily={fromFamily} customStyle={true} />
        )}
        {disabled && <div className='DisabledMessage'>{disabledMessage}</div>}
        <div className='Infos'>
          <h3 className='Name'>{graniteSecondary && graniteSecondary.reference !== granite.reference && !fromFamily ? `${granite.name} ${t("catalog_product_granits_and")}  ${t(`config3d_granit_${graniteSecondary.reference}`)}`: granite.name}</h3>
          {/* {price && <div className='Price'>A partir de {granite.price}€</div>} */}
        </div>
        <div className='pictos'>
          <ul>
            {pictos &&
              pictos.map((element, i) => {
                return (
                  <li key={i}>
                    <img src={element.picture} />
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}
